






































































import { SearchBuilder } from "@/builder";
import { FormModals } from "@/components/AccountingAccount";
import { DisplayBoolean } from "@/components/DisplayBoolean";
import { APagination, useCoa } from "@/hooks";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { SorterProps } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";

@Component({
  components: {
    DisplayBoolean,
    FormModals,
  },
  methods: {
    ...mapActions({
      resetStore: "accountingAccountStore/resetStore",
    }),
  },
})
export default class IndexPage extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  resetStore!: () => void;

  pagination = {
    page: +this.$route.query.p || FIRST_PAGE,
    limit: +this.$route.query.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = FIRST_PAGE;
    },
  };

  inputSearch: string = (this.$route.query.q as string) || "";

  loading = {
    find: false,
    submit: false,
  };

  dataCoa: Pagination<AccountingAccountResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  columns = [
    {
      title: this.$t("lbl_code"),
      dataIndex: "code",
      key: "code",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_account_name"),
      dataIndex: "description",
      key: "description",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_account_type"),
      dataIndex: "accountType.name",
      key: "accountType.name",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_active"),
      dataIndex: "active",
      scopedSlots: { customRender: "boolean" },
    },
    {
      title: this.$t("lbl_parent_account"),
      dataIndex: "parentName",
      key: "parentAccount.code",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_balance"),
      dataIndex: "balance",
      key: "balance",
      sorter: true,
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      scopedSlots: { customRender: "edit" },
    },
  ];

  modal = {
    visible: false,
    data: useCoa().initCoaDto(),
    toggle(): void {
      this.visible = !this.visible;
    },
    reset(): void {
      this.visible = false;
      this.data = useCoa().initCoaDto();
    },
  };

  mounted(): void {
    const params = this.buildQueryParams();
    this.fetchData(params);
  }

  fetchData(params?: RequestQueryParams): void {
    const { findList } = useCoa();

    this.loading.find = true;
    findList(params)
      .then(res => {
        this.dataCoa = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  updateQuerySearch(): void {
    this.$router.replace({
      name: "settings.coa",
      query: {
        q: this.inputSearch,
        p: this.pagination.page.toString(),
        l: this.pagination.limit.toString(),
      },
    });
  }

  handleSearch(): void {
    this.pagination.reset();

    const params = this.buildQueryParams();
    this.fetchData(params);
    this.updateQuerySearch();
  }

  buildQueryParams(): RequestQueryParams {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    params.sorts = "code:asc";
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;

    if (this.inputSearch) {
      params.search = builder
        .push(["code", this.inputSearch], {
          like: "both",
        })
        .or()
        .push(["description", this.inputSearch], {
          like: "both",
        })
        .build();
    }

    return params;
  }

  onChangeTable(pagination: APagination, _filter, sorts: SorterProps): void {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorts;

    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.page = FIRST_PAGE;
    }

    this.pagination.limit = pageSize;

    const params = this.buildQueryParams();

    if (order && order === "ascend") {
      params.sorts = `${columnKey}:asc`;
    } else if (order && order === "descend") {
      params.sorts = `${columnKey}:desc`;
    }

    this.fetchData(params);
    this.updateQuerySearch();
  }

  handleEdit(row: AccountingAccountResponseDto): void {
    this.modal.data = row;
    this.modal.toggle();
  }

  resetData(): void {
    this.pagination.reset();
    this.inputSearch = "";

    const params = this.buildQueryParams();
    this.fetchData(params);
  }

  handleModalClose(): void {
    this.resetStore();
    this.modal.reset();
  }

  onSuccess({ code }: { code?: string }): void {
    this.handleModalClose();
    if (code) {
      this.inputSearch = code;
      this.handleSearch();
    } else {
      const params = this.buildQueryParams();
      this.fetchData(params);
    }
  }

  openForm(): void {
    this.resetStore();
    this.modal.toggle();
  }
}
